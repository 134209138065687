﻿@mixin getWidth($cols) {
	width: (((100 / 12) * $cols) * 1%);
}

h1, h2, h3, h4, h5, h6 {
	font-size: $fontSize + 1;
	font-weight: bold;
	line-height: $fontHeight + 1;
	margin: 0;
	padding: 0 0 5px 0;
	text-transform: uppercase;
}

h1 {
	font-size: $fontSize + 5;
	line-height: $fontHeight + 5;
	padding-bottom: 10px;
}

h2 {
	font-size: $fontSize + 4;
	line-height: $fontHeight + 4;
	padding-bottom: 10px;
}

h3 {
	font-size: $fontSize + 3;
	line-height: $fontHeight + 3;
	padding-bottom: 5px;
}

h4 {
	font-size: $fontSize + 2;
	line-height: $fontHeight + 2;
	padding-bottom: 5px;
}

h5, h6 {
	padding-bottom: 0;
}

// header
.l-header {
	border-bottom: 2px solid $colorBlack;
	margin: 0;
	padding: 15px 0;
	text-align: center;

	&__logo {
		font-size: $fontSize + 5;
		font-weight: bold;
		line-height: $fontHeight + 5;
		margin: 0;
		padding: 0;
		text-transform: uppercase
	}
}

// content
.l-content {
	margin: 0;
	padding: 15px 0;

	&__title {
		font-size: $fontSize + 5;
		line-height: $fontHeight + 5;
		padding-bottom: 10px;
	}

	&__summary {
		font-size: $fontSize + 2;
		line-height: $fontHeight + 2;
	}
}

// footer
.l-footer {
	border-top: 2px solid $colorBlack;
	margin: 0;
	padding: 15px 0;

	&__copyright, &__created {
		font-size: $fontSize - 2;
		line-height: $fontHeight - 2;
		margin: 0;
		padding: 0;
	}

	&__copyright {
		float: left;
	}

	&__created {
		float: right;

		&:after {
			content: " - www.gibedigital.com";
		}
	}
}

// modules

// banner
.m-banner {
	font-size: $fontSize + 2;
	line-height: $fontHeight + 2;
	margin: 0 0 -15px 0;
	padding: 15px 0 0 0;
	text-align: center;

	&__title {
		font-size: $fontSize + 5;
		line-height: $fontHeight + 5;
		padding-bottom: 10px;
	}
}

// content block
.m-content-block {
	&__title {
		text-align: center;
	}
}

// content share
.m-content-share {
	&__title {
		text-align: center;
	}
}

// property list
.m-property-list {
	text-align: center;

	&__menu {
		list-style: none;
		margin: 0;
		padding: 0;

		&__item {
			@include box-sizing();
			list-style: none;
			margin: 0;
			padding: 0 0 15px 0;
			position: static !important;
			text-align: left;
			width: 100% !important;
		}
	}
}

// property preview
.m-property-preview {
	@include min-height(90px);
	margin: 0;
	padding: 5px 0 5px 215px;
	position: relative;
	z-index: 1;

	&__image {
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 0;
		width: 200px;
		z-index: 1;
	}

	&__title {
		margin: 0;
		padding: 0;
		@include text-overflow();
	}

	&__location {
		font-weight: bold;
		margin: 0;
		padding: 0;
		text-transform: uppercase;
		@include text-overflow();
	}

	&__summary {
		font-size: $fontSize - 1;
		line-height: $fontHeight - 1;
		margin: 0;
		overflow: hidden;
		padding: 0;
	}
}

// property
.m-property {
	&__intro {
		border-bottom: 1px dotted $colorDivider;
		margin: 0 0 15px 0;
		padding: 0 0 15px 0;

		&__image {
			float: right;
			width: 300px;
			max-width: 50%;
		}

		&__content {
			padding-right: 15px;
			overflow: hidden;
		}
	}

	&__location {
		border-bottom: 1px dotted $colorDivider;
		margin: 0 0 15px 0;
		padding: 0 0 15px 0;
	}
}

// explore
.m-explore {
	border-bottom: 1px dotted $colorDivider;
	margin: 0 0 15px 0;
	padding: 0 0 15px 0;

	&__title {
		text-align: center;
	}
}

// tab
.m-tab {
	border-bottom: 1px dotted $colorDivider;
	display: block !important;
	margin: 0 0 15px 0;
	padding: 0 0 15px 0;
}
